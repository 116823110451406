import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { environment } from "environments/environment";
import { ComplianceResponse } from "app/core/models/compliance-response";

@Injectable()
export class ComplianceService {

  constructor(private _httpClient: HttpClient) { }

  public getCompliances(filter: string, sortDirection: 'asc' | 'desc'| '', sortField: string, 
                      pageIndex: number, pageSize: number, activeFilters: Object[]): Observable<ComplianceResponse> {
    // seteamos los filters
    let allStringFilterAux = [];
    activeFilters.forEach((item, index) => {
      if (item['value'] != null && item['value'] != '') {
        let stringFilterAux = [item['id'],item['value']];
        allStringFilterAux.push(stringFilterAux.join('::'));
      }
    });

    let queryFilter = allStringFilterAux.join('--');

    // Seteamos los params
    let params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('page_size', pageSize.toString())
    .set('sf', sortField?.toString())
    .set('s', sortDirection?.toString().toUpperCase())
    .set('f', queryFilter);

    return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/compliance`, {
      params: params
      }).pipe(
          // map(res =>  res["data"])
          map(res =>  <ComplianceResponse>res)
      );
  }


  getComplianceStatus(): Observable < any > {
      return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/compliance/status`);
  }

  getComplianceSource(): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/compliance/source`);
}

  getCompliancesList(): Observable < any > {
      return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/compliance`);
  }

  getCompliance(id: string): Observable < any > {
      return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/compliance/${id}`);
  }

  createCompliance(compliance: any): Observable < any > {
      return this._httpClient.post(`${environment.apiUrl}/${environment.apiVersion}/compliance`, compliance);
  }

  editCompliance(compliance: any, id: string): Observable < any > {
      return this._httpClient.put(`${environment.apiUrl}/${environment.apiVersion}/compliance/${id}`, compliance);
  }

  deleteCompliance(id: string): Observable < any > {
      return this._httpClient.delete(`${environment.apiUrl}/${environment.apiVersion}/compliance/${id}`);
  }
  
}
