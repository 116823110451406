import {
    HttpClient,
} from '@angular/common/http';
import {
    inject,
    Injectable
} from '@angular/core';
import {
    UserService
} from 'app/core/user/user.service';
import {
    catchError,
    Observable,
    of,
    switchMap,
    throwError,
    take, filter, BehaviorSubject, tap, map
} from 'rxjs';
import {
    environment
} from './../../../environments/environment';
import {
    Router
} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _authenticated: boolean = false;
    private readonly _httpClient = inject(HttpClient);
    private readonly _userService = inject(UserService);
    private readonly API_URL = environment.apiUrl + '/' + environment.apiVersion;
    private isTokenRefreshing = false;
    private readonly refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private readonly _isLoggedIn = new BehaviorSubject<boolean>(this.isLogged());
    constructor(private readonly router: Router) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken');
    }

    /**
     * Setter & getter for access token
     */
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/users/recovery-password`, {
            email: email
        })
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable < any > {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${this.API_URL}/users/login`, credentials).pipe(
            switchMap((response: any) => {

                this.accessToken = response.token;
                this.refreshToken = response.refreshToken;
                console.log('new refresh token login:', response.refreshToken);


                return this.getUserById(response.user_id).pipe(
                    map((userResponse: any) => {
                        const user = {
                            id: userResponse.id,
                            avatar: userResponse.img ? userResponse.img : 'NO_AVATAR',
                            name: userResponse.name,
                            email: userResponse.email,
                            permissions: userResponse.permissions,
                            roles: userResponse.roles
                        };
                        localStorage.setItem('loggedUser', btoa(JSON.stringify(user)));
                        this._authenticated = true;
                        this._userService.user = user;
                        this._isLoggedIn.next(true);
                        return { ...response, user };
                    })
                );
            })
        );
    }


    /**
     * Sign in using the access token
     */

    signInUsingToken(): Observable < any > {
        // Sign in using the token
        return this._httpClient.post(`${this.API_URL}/users/refresh-token`, {
            refresh_token: this.refreshToken
        }).pipe(
            catchError(() =>

                // Return false
                of (false),
            ),
            switchMap((response: any) => {

                if (response.token) {
                    this.accessToken = response.token;
                    this.refreshToken = response.refreshToken;
                    console.log('new refresh token sign in:', response.refreshToken);

                }

                // Set the authenticated flag to true
                this._authenticated = true;
                this._isLoggedIn.next(true);

                // Store the user on the user service
                // this._userService.user = response.user;

                // Return true
                return of(true);
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable < any > {
        // TODO IYAN
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        this._authenticated = false;
        this._isLoggedIn.next(false);
        this.router.navigate(['./sign-out']);

        // this._httpClient.get(`${this.API_URL}/users/logout`).subscribe(el => {
        //     localStorage.removeItem('accessToken');
        //     localStorage.removeItem('refreshToken');
        //     this._authenticated = false;
        // })
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        email: string;
        password: string;
    }): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/users/signup`, user)
    }

    /**
     * Verify user
     *
     * @param user
     */
    verifyUser(user: {
        email: string;
        verification_code: string;
    }): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/users/activate`, user)
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;password: string
    }): Observable < any > {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Change password
     *
     * @param passwords
     */
    changePassword(passwords: {
        password: string;old_password: string
    }): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/users/change-password`, passwords);
    }

    /**
     * Check the authentication status
     */
    check(): Observable < boolean > {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        // if (AuthUtils.isTokenExpired(this.accessToken)) {
        //     return of(false);
        // }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    getUserById(id: string): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/users/${id}`);
    }

    getAccounts(): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/accounts`);
    }

    callRefreshToken(): Observable<any> {
        if (this.isTokenRefreshing) {
            return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1)
            );
        }

        this.isTokenRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this.doRefreshToken().pipe(
            tap((newToken) => {
                this.isTokenRefreshing = false;
                this.refreshTokenSubject.next(newToken);
            }),
            catchError((error) => {
                this.isTokenRefreshing = false;
                this.refreshTokenSubject.next(false);
                return of(false);
            })
        );
    }

    private doRefreshToken(): Observable<any> {

        // Use your existing logic to refresh token with http call
        return this._httpClient.post(`${this.API_URL}/users/refresh-token`, {
            refresh_token: this.refreshToken
        }).pipe(
            catchError(() => of(true)), // Return false on errors
            switchMap((response: any) => {
                if (response.token) {
                    this.accessToken = response.token;
                    this.refreshToken = response.refreshToken;
                    console.log('new refresh token:', response.refreshToken);
                    // console.log('new token:', response.accessToken.slice(-10));
                }
                this._authenticated = true;
                this._isLoggedIn.next(true);
                // Store user on user service (if applicable)
                // this._userService.user = response.user;
                return of(true);
            })
        );
    }
    public isRefreshing(): boolean {
        return this.isTokenRefreshing;
    }

    public getRefreshTokenSubject(): BehaviorSubject<any> {
        return this.refreshTokenSubject;
    }
    public isLogged(): boolean {
        return this._authenticated;
    }
    isLoggedIn(): Observable<boolean> {
        return this._isLoggedIn.asObservable().pipe(
            tap(isLogged => {
                // console.log('Is logged in:', isLogged);
            }),
            catchError(() => of(false))
        );
    }


}
