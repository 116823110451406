import {
    Injectable
} from "@angular/core";
import {
    HttpClient, HttpParams
} from "@angular/common/http";
import {
    environment
} from "environments/environment";
import { Observable } from "rxjs";
import { LinkResponse } from "app/core/models/link-response";
import { Link } from "app/core/models/link";
import { delay, map } from 'rxjs/operators';

@Injectable()
export class LinksService {

    constructor(private _httpClient: HttpClient) {}

    postShortenedLinks(data: Link): Observable < any > {
        return this._httpClient.post(`${environment.apiUrl}/${environment.apiVersion}/shortened_links`, data );
    }
    putShortenedLinks(id: string, data: Link): Observable < any > {
        return this._httpClient.put(`${environment.apiUrl}/${environment.apiVersion}/shortened_links/${id}`, data );
    }

    getShortenedLinks(id: string): Observable < any > {
        return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/shortened_links/${id}`);
    }


    public getShortenedLinksList(filter: string, sortDirection: 'asc' | 'desc'| '', sortField: string,
    pageIndex: number, pageSize: number, activeFilters: Object[]): Observable<LinkResponse> {
        // seteamos los filters
        let allStringFilterAux = [];
        activeFilters.forEach((item, index) => {
            if (item['value'] != null && item['value'] != '') {
            let stringFilterAux = [item['id'],item['value']];
            allStringFilterAux.push(stringFilterAux.join('::'));
            }
        });

        let queryFilter = allStringFilterAux.join('--');

        // Seteamos los params
        let params = new HttpParams()
        .set('page', pageIndex.toString())
        .set('page_size', pageSize.toString())
        .set('sf', sortField?.toString())
        .set('s', sortDirection?.toString().toUpperCase())
        .set('f', queryFilter);

        return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/shortened_links`, {
        params: params
        }).pipe(
        map(res =>  <LinkResponse>res)
        );    
    }
}
