import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'confirmation-success', loadChildren: () => import('app/modules/auth/confirmation-success/confirmation-success.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'dashboard', loadChildren: () => import('app/modules/admin/pages/dashboard/analytics/analytics.routes')},
            {path: 'links', loadChildren: () => import('app/modules/admin/pages/links/links.routes')},

            {path: 'users', loadChildren: () => import('app/modules/admin/pages/users/users.routes')},
            {path: 'users/read/:id', loadChildren: () => import('app/modules/admin/pages/users/read/read.routes')},
            {path: 'users/edit/:id', loadChildren: () => import('app/modules/admin/pages/users/edit/edit.routes')},
            {path: 'users/create', loadChildren: () => import('app/modules/admin/pages/users/edit/edit.routes')},
            {path: 'users/me/read', loadChildren: () => import('app/modules/admin/pages/users/read/read.routes')},
            {path: 'users/me/edit', loadChildren: () => import('app/modules/admin/pages/users/edit/edit.routes')},            

            {path: 'statistics', loadChildren: () => import('app/modules/admin/pages/statistics/dashboard/statistics.routes')},
            {path: 'payments', loadChildren: () => import('app/modules/admin/pages/payments/payments.routes')},
            {path: 'campaigns', loadChildren: () => import('app/modules/admin/pages/campaigns/campaigns.routes')},
            {path: 'faq', loadChildren: () => import('app/modules/admin/pages/faq/faq.routes')},
            {path: 'support', loadChildren: () => import('app/modules/admin/pages/support/support.routes')},
            {path: 'compliance', loadChildren: () => import('app/modules/admin/pages/compliances/compliances.routes')},

            {path: 'compliance/read/:id', loadChildren: () => import('app/modules/admin/pages/compliances/read-view/read-view.routes')},
            {path: 'compliance/edit/:id', loadChildren: () => import('app/modules/admin/pages/compliances/add-edit-view/add-edit-view.routes')},
            {path: 'compliance/create', loadChildren: () => import('app/modules/admin/pages/compliances/add-edit-view/add-edit-view.routes')},
        ]
    },
    {path: '**', pathMatch : 'full', redirectTo: ''},

];
