<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img *ngIf="userData.avatar != 'NO_AVATAR'"
            class="w-7 h-7 rounded-full" [src]="'data:image/png;base64,'+userData.avatar">

            <img *ngIf="userData.avatar === 'NO_AVATAR'"
            class="w-7 h-7 rounded-full" src="assets/images/avatars/default.png">
            
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{'Signed in as' | transloco}}</span>
            <span class="mt-1.5 text-md font-medium">{{userData.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="goSettings()">
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Settings</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>
