import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import {environment} from "../../../../environments/environment";
import { Notification } from "app/core/models/notification";
import {NotificationResponse} from "app/core/models/notification-response";

@Injectable({providedIn: 'root'})
export class NotificationsService
{
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]>
    {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getAll(): Observable<Notification[]> {

        let params = new HttpParams()
            .set('page', '0')
            .set('page_size', '50')
            // .set('sf', 'creation_date')
            .set('s', 'asc')


        return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/alerts`, {
            params: params
        }).pipe(
            map((response: NotificationResponse) => response.data || []), // Extraer la propiedad data o devolver un arreglo vacío si es undefined
            tap((notifications) => {
                this._notifications.next(notifications);
            })
        );
    }
    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification>
    {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', {notification}).pipe(
                map((newNotification) =>
                {
                    // Update the notifications with the new notification
                    this._notifications.next([...notifications, newNotification]);

                    // Return the new notification from observable
                    return newNotification;
                }),
            )),
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: Notification): Observable<Notification>
    {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.patch<Notification>('`${environment.apiUrl}/${environment.apiVersion}/alerts/${id}', {
                id,
                notification,
            }).pipe(
                map((updatedNotification: Notification) =>
                {
                    // Find the index of the updated notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Update the notification
                    notifications[index] = updatedNotification;

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated notification
                    return updatedNotification;
                }),
            )),
        );
    }


    /**
     * Mark a notification as viewed
     * @param id - The ID of the notification to mark as viewed
     * @returns Observable<Notification>
     */
    markAsViewed(id: string): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications =>
                this._httpClient.post<Notification>(`${environment.apiUrl}/${environment.apiVersion}/alerts/${id}/viewed`, {}).pipe(
                    map((updatedNotification: Notification) => {
                        // Find the index of the notification
                        const index = notifications.findIndex(item => item.id === id);

                        notifications[index] = { ...notifications[index], viewed: true };

                        this._notifications.next(notifications);

                        return updatedNotification;
                    }),
                ),
            ),
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean>
    {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.delete<boolean>(`${environment.apiUrl}/${environment.apiVersion}/alerts/${id}`, {params: {id}}).pipe(
                map((isDeleted: boolean) =>
                {
                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean>
    {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
                map((isUpdated: boolean) =>
                {
                    // Go through all notifications and set them as read
                    notifications.forEach((notification, index) =>
                    {
                        notifications[index].viewed = true;
                    });

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated status
                    return isUpdated;
                }),
            )),
        );
    }
}
